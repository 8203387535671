import { mapGetters } from 'vuex'
import { getFormattedPrice } from '@/formatter'
import { DateTime } from 'luxon'

export default {
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
  },
  methods: {
    getFormattedPrice(value) {
      return getFormattedPrice(value)
    },
    getFormattedDate(timestamp) {
      const date = new Date(timestamp * 1000)

      const formatter = new Intl.DateTimeFormat(
        this.locale,
        {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        },
      )

      return formatter.format(date)
    },

    getFormattedOrderHistoryMessage(message) {
      if (message === 'ASK_TO_BILL_ACTIVE') {
        return this.$t('notificationOrdersSessionAskToBillActive')
      }
      if (message === 'ASK_TO_BILL_INACTIVE') {
        return this.$t('notificationOrdersSessionAskToBillInactive')
      }
      if (message === 'CALL_SERVER_ACTIVE') {
        return this.$t('notificationOrdersSessionCallServerActive')
      }
      if (message === 'CALL_SERVER_INACTIVE') {
        return this.$t('notificationOrdersSessionCallServerInactive')
      }
      if (message === 'START_SESSION') {
        return this.$t('notificationOrdersSessionStarted')
      }
      if (message === 'END_SESSION') {
        return this.$t('notificationOrdersSessionClosed')
      }
      if (message === 'ORDER_UPDATE_ADD') {
        return this.$t('notificationOrdersSessionUpdateAdd')
      }
      if (message === 'ORDER_UPDATE_REMOVE') {
        return this.$t('notificationOrdersSessionUpdateRemove')
      }
      if (message === 'ORDER_UPDATE_NO_CHARGE') {
        return this.$t('notificationOrdersSessionNoCharge')
      }
      if (message === 'ORDER_UPDATE_GUESTS') {
        return this.$t('notificationOrdersSessionGuests')
      }
      return this.$t('notificationOrdersSessionUpdate')
    },
    getFormattedOrderHistoryTimestamp(timestamp) {
      return DateTime
        .fromISO(timestamp)
        .setLocale(navigator.language)
        .toLocaleString(DateTime.TIME_SIMPLE)
    },
  },
}
