import {
  required,
  integerDouble,
  max,
} from '@validations'

import {
  BRow,
  BCol,
  BCollapse,
  BOverlay,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  VBTooltip,

  BCardHeader,
  BFormRadio,
  BButton,
} from 'bootstrap-vue'

import NameTitle from '@/components/NameTitle.vue'
import ItemPreparation from '@/components/ItemPreparation.vue'
import TextareaCounter from '@/components/TextareaCounter.vue'
import ItemGroup from '@/components/ItemGroup.vue'
import ItemVideosImages from '@/components/ItemVideosImages.vue'
import ItemBadgesTags from '@/components/ItemBadgesTags.vue'
import ItemBadgesTagsTranslations from '@/components/ItemBadgesTagsTranslations.vue'
import IconPreview from '@/components/icon/IconPreview.vue'
import ModalPreview from '@/components/modal/ModalPreview.vue'
import Translate from '@/components/Translate.vue'
import CardSuggestions from '@/components/card/CardSuggestions.vue'
import CardPricing from '@/components/card/CardPricing.vue'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import IconStopList from '@/components/icon/IconStopList.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'

import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinRequests from '@/mixins/requests'
import mixinTranslationsCreateUpdate from '@/mixins/translations_create-update'
import mixinFormatter from '@/mixins/formatter'
import mixinGoogleTranslation from '@/mixins/google-translation'
import mixinGqlBadgesTags from '@/mixins/gql/badges-tags'

import UPDATE_MENU_ITEM from '@/gql/mutation/menuItem/updateMenuItem.gql'
import DUPLICATE_MENU_ITEM from '@/gql/mutation/menuItem/duplicateMenuItem.gql'
import DELETE_MENU_ITEM from '@/gql/mutation/menuItem/deleteMenuItem.gql'
import CREATE_MENU_ITEM_TRANSLATION from '@/gql/mutation/menuItem/createMenuItemTranslation.gql'
import UPDATE_MENU_ITEM_TRANSLATION from '@/gql/mutation/menuItem/updateMenuItemTranslation.gql'
import GET_MENU_ITEM_TRANSLATIONS from '@/gql/query/menu-managment/getMenuItemTranslations.gql'

export default {
  mixins: [
    mixinRequests,
    mixinTranslationsCreateUpdate,
    mixinGoogleTranslation,
    mixinFormatter,
    mixinGqlBadgesTags,
  ],
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCollapse,
    BOverlay,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    VBTooltip,

    BCardHeader,
    BFormRadio,
    BButton,

    NameTitle,
    ItemPreparation,
    TextareaCounter,
    ItemGroup,
    ItemVideosImages,
    ItemBadgesTags,
    ItemBadgesTagsTranslations,
    IconPreview,
    ModalPreview,
    Translate,
    CardSuggestions,
    CardPricing,
    IconStopList,
    IconTranslate,
    ModalConfirmDelete,

    BCardActions,
    draggable,
    Treeselect,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isDuplicating: false,
      isFetching: true,

      isEnabled: true,
      isVisible: true,

      isCardContent: true,

      name: '',
      description: '',
      longDescription: '',

      offers: [],

      videos: [],
      images: [],

      tags: [],
      allergens: [],
      allergensNormalizer(node) {
        let result = { ...node, label: node.name }
        if (node.items && node.items.length) {
          result = { ...result, children: node.items }
        }
        return result
      },

      translations: [],
      translationActive: '',
      isTranslateMode: false,

      suggestionName: '',
      suggestionItems: [],

      badges: [],

      preparation: {
        time: null,
        isInMenus: false,
      },

      items: [],

      textLimitShort: 250,
      textLimitFull: 500,
      textLimitSuggestions: 100,

      isModalConfirmDelete: false,
      isModalPreview: false,

      createTranslationResponseObjectName: 'createMenuItemTranslation',
      createTranslationMutation: CREATE_MENU_ITEM_TRANSLATION,
      updateTranslationMutation: UPDATE_MENU_ITEM_TRANSLATION,

      // validation rules
      required,
      integerDouble,
      max,
    }
  },
  computed: {
    ...mapGetters({
      isMenusFetched: 'menuManagement/isMenusFetched',
      isMenuItemsFetched: 'menuManagement/isMenuItemsFetched',
      restaurant: 'restaurant/restaurant',
      menuItems: 'menuManagement/menuItems',
      menus: 'menuManagement/menus',
      isInfo: 'main/isInfo',
      subscription: 'billing/subscription',
      currencySymbol: 'restaurant/currencySymbol',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
      allergensList: 'dictionaries/allergens',
      locale: 'main/locale',
      languages: 'dictionaries/languages',

      isBadgesFetched: 'badgesTags/isBadgesFetched',
      badgesFromStore: 'badgesTags/badges',
      badgesTranslations: 'badgesTags/badgesTranslations',
      isTagsFetched: 'badgesTags/isTagsFetched',
      tagsFromStore: 'badgesTags/tags',
      tagsTranslations: 'badgesTags/tagsTranslations',
    }),
    previewUrl() {
      if (!this.offers.length) {
        return ''
      }

      const offer = this.offers[this.offers.length - 1]
      const queryParams = `restaurant=${this.restaurant ? this.restaurant.id : null}&menu=${offer.id}`
      return `${process.env.VUE_APP_ORIGIN_CLIENT}/preview/item/${offer.categories[0].items[0].id}?${queryParams}`
    },
    optionsTreeselectItems() {
      return this.menuItems
        .filter(i => i.type === 'MenuItem')
        .map(i => ({ ...i, label: i.name }))
    },
    optionsAllergens() {
      const getAllergenTranslation = (node, language) => {
        const { translations, ...rest } = node
        let result = rest

        const translation = translations.find(j => j.languageCode === language.shortName)
        if (translation) {
          const { languageCode, ...restTranslation } = translation
          result = { ...result, ...restTranslation }
        }

        if (result.items && result.items.length) {
          result = { ...result, items: result.items.map(i => getAllergenTranslation(i, language)) }
        }

        return result
      }

      const language = this.languages.find(i => i.iso6391 === this.locale) || { shortName: 'eng' }
      return this.allergensList.map(i => getAllergenTranslation(i, language))
    },
  },
  watch: {
    isMenusFetched() {
      this.fetch()
    },
    isMenuItemsFetched() {
      this.fetch()
    },
    isBadgesFetched() {
      this.fetch()
    },
    isTagsFetched() {
      this.fetch()
    },
    $route: {
      immediate: true,
      handler() {
        this.fetch()
      },
    },
  },
  methods: {
    ...mapActions({
      setNavHistory: 'main/setNavHistory',
      getMenuItems: 'menuManagement/getMenuItems',
      setMenuItems: 'menuManagement/setMenuItems',
      setIsInfo: 'main/setIsInfo',
      getMenus: 'menuManagement/getMenus',
      updateMenuItem: 'menuManagement/updateMenuItem',

      setBadges: 'badgesTags/setBadges',
      setBadgesTranslations: 'badgesTags/setBadgesTranslations',
      setTags: 'badgesTags/setTags',
      setTagsTranslations: 'badgesTags/setTagsTranslations',
    }),

    updateHistory() {
      const history = ['item', this.$route.params.id]
      this.$emit('setLastOpened', history)
      this.setNavHistory(history)
    },
    async getTranslations(menuItemId) {
      const response = await this.$apollo.query({
        query: GET_MENU_ITEM_TRANSLATIONS,
        variables: {
          getMenuItemTranslationsId: menuItemId,
        },
      })
      return response.data.getMenuItemTranslations
    },
    setMainData(menuItem) {
      this.isEnabled = menuItem.isEnabled
      this.isVisible = menuItem.isVisible

      this.name = menuItem.name
      this.badges = [...menuItem.badges]
      this.tags = [...menuItem.tags]
      this.allergens = menuItem.allergens.map(i => i.id)
      this.description = menuItem.description || ''
      this.longDescription = menuItem.longDescription || ''

      this.setOffers()

      // eslint-disable-next-line no-undef
      this.items = structuredClone(menuItem.items)
      if (
        (
          this.items.length
          && this.$refs['card-consist']
          && !this.$refs['card-consist'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        ) || (
          !this.items.length && this.$refs['card-consist']
          && this.$refs['card-consist'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        )
      ) {
        this.$refs['card-consist'].$el.getElementsByClassName('card-header')[0].click()
      }

      this.videos = [...menuItem.videos]
      this.images = [...menuItem.images]

      this.suggestionName = menuItem.suggestionName
      // eslint-disable-next-line no-undef
      this.suggestionItems = structuredClone(menuItem.suggestionItems)

      this.preparation.time = menuItem.preparationTime || ''
      this.preparation.isInMenus = !!menuItem.showPreparationTime
    },

    getTranslationPayload(i) {
      return {
        language: i.language,

        name: i.name || null,
        description: i.description || null,
        longDescription: i.longDescription || null,
        suggestionName: i.suggestionName || null,

        options: i.options.map(j => ({
          ...j,
          name: j.name || null,
          items: j.items
            .filter((k, kIndex) => kIndex + 1 !== j.items.length)
            .map(k => ({ ...k, name: k.name || null })),
        })),
        extras: i.extras.map(j => ({
          ...j,
          name: j.name || null,
          items: j.items.map(k => ({ ...k, name: k.name || null })),
        })),
      }
    },
    getTranslationPayloadClear(language) {
      return {
        language,

        name: null,
        description: null,
        longDescription: null,
        suggestionName: null,

        options: this.options
          ? this.options.map(i => ({
            id: i.id,
            name: null,
            type: i.type,
            items: i.items.map(j => ({ id: j.id, name: null })),
          }))
          : [],
        extras: this.extras
          ? this.extras.map(i => ({
            id: i.id,
            name: null,
            sameAddPrice: i.sameAddPrice,
            items: i.items.filter(j => j.type === 'SimpleItem').map(j => ({ id: j.id, name: null })),
          }))
          : [],
      }
    },
    onClearTranslation() {
      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,

            name: null,
            description: null,
            longDescription: null,
            suggestionName: null,

            options: i.options.map(j => ({
              ...j,
              name: null,
              items: j.items.map(k => ({ ...k, name: null })),
            })),
            extras: i.extras.map(j => ({
              ...j,
              name: null,
              items: j.items.map(k => ({ ...k, name: null })),
            })),
          }
        }
        return i
      })
      this.patchTranslationActive()

      const refBadges = this.$refs['item-badges-tags-translations_badges']
      refBadges.itemsTranslationsToRender = refBadges.itemsTranslationsToRender.map(i => ({ ...i, name: '' }))
      refBadges.saveItemsTranslations(refBadges.itemsTranslationsToRender)

      const refTags = this.$refs['item-badges-tags-translations_tags']
      refTags.itemsTranslationsToRender = refTags.itemsTranslationsToRender.map(i => ({ ...i, name: '' }))
      refTags.saveItemsTranslations(refTags.itemsTranslationsToRender)
    },

    onResetOffers() {
      this.setOffers()
    },
    setOffers() {
      let result = []

      this.menus.forEach(i => {
        i.categories.forEach(j => {
          j.items.forEach(k => {
            if (k.item.id === this.$route.params.id) {
              result = [
                ...result,
                {
                  ...i,
                  categories: [
                    {
                      ...j,
                      items: [
                        {
                          ...k,
                          price: this.getFormattedPrice(k.price),
                        },
                      ],
                    },
                  ],
                },
              ]
            }
          })
        })
      })

      // eslint-disable-next-line no-undef
      this.offers = structuredClone(result)
    },

    openPreviewModal() {
      if (!this.offers.length) {
        return
      }

      this.isModalPreview = true
    },
    setTranslateMode(event) {
      this.isTranslateMode = event
      this.$emit('setSidebarState', !event)
    },

    patchBase(name, value) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_MENU_ITEM,
          variables: {
            updateMenuItemId: this.$route.params.id,
            [name]: value,
          },
        }),
      ]
    },

    patchEnabled() {
      return this.patchBase('isEnabled', this.isEnabled)
    },
    onClickEnabled() {
      this.isEnabled = !this.isEnabled
      this.updateMenuItem([this.$route.params.id, 'isEnabled', this.isEnabled])
      this.requests.push('patchEnabled', () => [this.getMenus(), this.getMenus()])
    },
    patchVisible() {
      return this.patchBase('isVisible', this.isVisible)
    },
    onClickVisible() {
      this.isVisible = !this.isVisible
      this.updateMenuItem([this.$route.params.id, 'isVisible', this.isVisible])
      this.requests.push('patchVisible', () => [this.getMenus(), this.getMenus()])
    },

    patchName() {
      return this.patchBase('name', this.name)
    },
    saveName() {
      this.updateMenuItem([this.$route.params.id, 'name', this.name])

      this.requests.push(
        'patchName',
        () => [
          this.getMenus(),
          this.getMenuItems(),
        ],
      )
    },
    onSaveName(event) {
      this.name = event
      this.saveName()
    },
    async onBlurName(refName) {
      if (this.name === this.menuItems.find(i => i.id === this.$route.params.id).name) {
        return
      }

      const isValid = await this.$refs[refName].validate()
      if (!isValid) {
        return
      }

      this.saveName()
    },

    patchBadges() {
      return this.patchBase('badges', this.badges.map(i => i.id))
    },
    onInputBadges(event) {
      this.badges = event
      this.updateMenuItem([this.$route.params.id, 'badges', this.badges.map(i => ({ id: i.id, name: i.name }))])
      this.requests.push('patchBadges')
    },
    patchTags() {
      return this.patchBase('tags', this.tags.map(i => i.id))
    },
    onInputTags(event) {
      this.tags = event
      this.updateMenuItem([this.$route.params.id, 'tags', this.tags.map(i => ({ id: i.id, name: i.name }))])
      this.requests.push('patchTags')
    },
    patchAllergens() {
      return this.patchBase('allergens', this.allergens)
    },
    updateAllergens() {
      this.updateMenuItem([this.$route.params.id, 'allergens', this.allergens.map(i => ({ id: i }))])
      this.requests.push('patchAllergens')
    },
    onCloseTreeselectAllergens() {
      this.updateAllergens()
    },
    patchDescription() {
      return this.patchBase('description', this.description)
    },
    async onBlurDescription(refName) {
      const isValid = await this.$refs[refName].validate()
      if (isValid) {
        this.updateMenuItem([this.$route.params.id, 'description', this.description])
        this.requests.push('patchDescription')
      }
    },
    patchLongDescription() {
      return this.patchBase('longDescription', this.longDescription)
    },
    async onBlurLongDescription(refName) {
      const isValid = await this.$refs[refName].validate()
      if (isValid) {
        this.updateMenuItem([this.$route.params.id, 'longDescription', this.longDescription])
        this.requests.push('patchLongDescription')
      }
    },

    patchVideos() {
      return this.patchBase('videos', this.videos)
    },
    onInputYoutubeLinks(event) {
      this.videos = [...event]
      this.updateMenuItem([this.$route.params.id, 'videos', this.videos])
      this.requests.push('patchVideos')
    },

    onSaveSuggestionName() {
      this.updateMenuItem([this.$route.params.id, 'suggestionName', this.suggestionName])
      this.requests.push(() => this.patchBase('suggestionName', this.suggestionName))
    },
    async onBlurSuggestionName() {
      const isValid = await this.$refs.suggestionsName.validate()
      if (isValid) {
        this.onSaveSuggestionName()
      }
    },
    onSaveSuggestionItems() {
      this.updateMenuItem([this.$route.params.id, 'suggestionItems', this.suggestionItems])
      this.requests.push(() => this.patchBase('suggestionItems', this.suggestionItems.map(i => i.id)))
    },

    async onClickTranslateField(path) {
      await this.translateField(path)
      this.patchTranslationActive()
    },

    onInputImages(event) {
      this.images = event
      this.updateMenuItem([this.$route.params.id, 'images', this.images])
    },

    patchPreparationTime() {
      return this.patchBase('preparationTime', this.preparation.time)
    },
    onSavePreparationTime() {
      this.updateMenuItem([this.$route.params.id, 'preparationTime', this.preparation.time])
      this.requests.push('patchPreparationTime')
    },

    patchPreparationIsInMenus() {
      return this.patchBase('showPreparationTime', this.preparation.isInMenus)
    },
    onSavePreparationIsInMenus() {
      this.updateMenuItem([this.$route.params.id, 'showPreparationTime', this.preparation.isInMenus])
      this.requests.push('patchPreparationIsInMenus')
    },

    patchItems() {
      return this.patchBase('items', this.items.map(i => i.id))
    },
    saveItems() {
      this.updateMenuItem([this.$route.params.id, 'items', this.items])
      this.requests.push('patchItems')
    },
    onCloseTreeselectItems() {
      this.saveItems()
    },
    onClickDeleteItem(id) {
      this.items = this.items.filter(i => i.id !== id)
      this.saveItems()
    },

    showNotificationToFixValidation() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Error'),
          text: this.$t('notificationErrorValidateData'),
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    },

    async onClickTranslateItem(type, id, itemId) {
      const value = this[type].find(i => i.id === id).items.find(i => i.id === itemId).name
      if (!value) return

      const response = await this.getGoogleTranslation([value])
      if (response) {
        const [responseValue] = response

        const translation = this.translations.find(i => i.language === this.translationActive)
        translation[type].find(i => i.id === id).items.find(i => i.id === itemId).name = responseValue

        this.patchTranslationActive()
      }
    },

    async onClickDuplicate() {
      this.isDuplicating = true

      try {
        const response = await this.$apollo.mutate({
          mutation: DUPLICATE_MENU_ITEM,
          variables: {
            duplicateMenuItemId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
        ])

        await this.$router.push({
          name: 'menu-item',
          params: {
            id: response.data.duplicateMenuItem.id,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDuplicating = false
      }
    },

    async onDelete() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_MENU_ITEM,
          variables: {
            deleteMenuItemId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
        ])

        await this.$router.push({ name: 'menu-management' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
