<template>
  <div
    v-if="isEditMode"
    v-click-outside="onClickOutside"
    class="option-extra-name flex-grow-1"
  >
    <slot />
  </div>
  <div
    v-else
    class="option-extra-name pt-25 pb-25"
    @click.stop="onClickEdit"
  >
    <h5 class="mb-0 pt-25 pb-25">
      {{ value }}
      <feather-icon
        v-b-tooltip.hover.top="$t('Edit')"
        icon="Edit3Icon"
        role="button"
        size="20"
        class="option-extra-name__edit-icon"
      />
    </h5>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import directiveClickOutside from '@/directives/clickOutside'

export default {
  name: 'OptionExtraName',
  directives: {
    'b-tooltip': VBTooltip,
    clickOutside: directiveClickOutside,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isEditMode: false,
    }
  },
  methods: {
    onClickEdit() {
      setTimeout(() => {
        this.isEditMode = true
      })
    },
    async onClickOutside() {
      this.isEditMode = false
    },
  },
}
</script>

<style lang="sass">
.option-extra-name
  &:hover
    .option-extra-name
      &__edit-icon
        visibility: visible
  &__edit-icon
    visibility: hidden
    vertical-align: bottom
</style>
